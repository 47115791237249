import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// 新手指南
import Guide from "pages/guide";
import list from "pages/list";
import detail from "pages/detail";
import notFound from "pages/notFound";

// 下载
import DownLoad from "pages/download";
import DownDetail from "pages/download/detail";

// 活动中心
import ActivityCenter from "pages/activityCenter";

import Help from "pages/help";

// 帮助中心
import Faq from "pages/faq";
import Cat from "pages/faq/cat";

import Category from "pages/category";

import Beginner_Guide from "pages/beginner_guide";
import Deposit_Guide from "pages/deposit_guide";

import Detail_New from "pages/detail_new";
import Infor_Detail from "pages/inforDetail";
import Message_Details from "pages/messageDetails";

import Withdrawa_Guide from "pages/withdrawa_guide";
import Novice_Book from "pages/novice_book";

export default () => (
  <Router>
    <Switch>
      <Route exact path="/" component={DownLoad} />
      <Route path="/detail/:id" component={detail} />
      <Route path="/list/:id" component={list} />
      <Route path="/guide" component={Guide} />
      <Route path="/activity-center" component={ActivityCenter} />

      <Route path="/download/detail" component={DownDetail} />
      <Route path="/help/:id" component={Help} />
      <Route path="/faq/:id" component={Faq} />
      <Route path="/cat/:id" component={Cat} />

      {/* 新手指南 */}
      <Route path="/beginner_guide" component={Beginner_Guide} />
      {/* 存款指南 */}
      <Route path="/deposit_guide" component={Deposit_Guide} />
      {/* 指南问题详情 */}
      <Route path="/detail_new/:id" component={Detail_New} />
      {/* 资讯详情 */}
      <Route path="/infor_detail/:id" component={Infor_Detail} />
      {/* 系统消息详情 */}
      <Route path="/message_detail/:id" component={Message_Details} />
      {/* 取款须知 */}
      <Route path="/withdraw_guide" component={Withdrawa_Guide} />
      {/* 新手宝典 */}
      <Route path="/novice_book" component={Novice_Book} />

      <Route path="/:category/:id" component={Category} />
      <Route path="*" component={notFound} />
    </Switch>
  </Router>
);
